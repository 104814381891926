import { fullName } from '../constants';

import { buildGalleryRoute } from './helpers';

const galleryElement = document.getElementById('gallery')!;
const galleryImageElement =
  galleryElement.querySelector<HTMLImageElement>('#gallery-image')!;
const galleryDescriptionElement = document.getElementById(
  'gallery-description',
)!;
export const galleryCloseButtonElement = document.getElementById(
  'gallery-close-button',
)!;

const galleryImages = [
  ...document.querySelectorAll<HTMLElement>('.showcase-item'),
].map((element, index) => getImageDataFromElement(element, index));

export type ImageData = {
  slug: string;
  name: string;
  url: string;
  descriptionHtml: string;
  index: number;
  showcaseItemElement: HTMLElement;
};

export const galleryPathnameRegex = /^\/gallery\/([\dA-Za-z-]+)$/g;

export let selectedGalleryImage: ImageData | null;

export function openGallery(image: ImageData, pushHistory: boolean): void {
  selectGalleryImage(image, false);
  galleryElement.classList.remove('display-none');
  document.body.classList.add('overflow-hidden');
  galleryCloseButtonElement.focus();

  if (pushHistory) {
    window.history.pushState(null, fullName, buildGalleryRoute(image.slug));
  }

  window.addEventListener('keydown', handleKeyDown);
}

function handleKeyDown(event: KeyboardEvent): void {
  if (event.key === 'Escape') {
    closeGallery(true);
  } else if (event.key === 'ArrowLeft') {
    navigateGallery('next', true);
  } else if (event.key === 'ArrowRight') {
    navigateGallery('previous', true);
  }
}

function navigateGallery(
  direction: 'next' | 'previous',
  replaceHistory: boolean,
): void {
  if (!selectedGalleryImage || galleryImages.length <= 1) {
    return;
  }

  let newImage: ImageData;

  if (direction === 'next') {
    newImage =
      selectedGalleryImage.index === galleryImages.length - 1
        ? galleryImages[0]
        : galleryImages[selectedGalleryImage.index + 1];
  } else {
    newImage =
      selectedGalleryImage.index === 0
        ? galleryImages[galleryImages.length - 1]
        : galleryImages[selectedGalleryImage.index - 1];
  }

  selectGalleryImage(newImage, replaceHistory);
}

export function closeGallery(goBack: boolean): void {
  window.removeEventListener('keydown', handleKeyDown);

  galleryElement.classList.add('display-none');
  document.body.classList.remove('overflow-hidden');

  if (selectedGalleryImage) {
    selectedGalleryImage.showcaseItemElement.focus();
    selectedGalleryImage = null;
    setGalleryElements(null);
  }

  if (goBack && window.history.length > 1) {
    window.history.back();
  }
}

export function maybeRouteToGallery(): void {
  const image = getImageDataFromLocation();

  if (!image) {
    return;
  }

  openGallery(image, true);
}

export function getImageDataFromLocation(): ImageData | null {
  const slug = getImageSlugFromLocation();

  if (!slug) {
    return null;
  }

  return getImageDataFromSlug(slug);
}

function getImageSlugFromLocation(): string | null {
  const results = [...window.location.pathname.matchAll(galleryPathnameRegex)];

  if (results.length === 0) {
    return null;
  }

  const slug = results[0][1];

  return slug;
}

export function getImageDataFromSlug(slug: string): ImageData | null {
  return galleryImages.find((image) => image.slug === slug) ?? null;
}

export function getImageDataFromElement(
  element: HTMLElement,
  index: number,
): ImageData {
  return {
    slug: element.dataset.slug!,
    name: element.dataset.name!,
    url: element.dataset.url!,
    descriptionHtml: element.dataset.descriptionHtml!,
    index,
    showcaseItemElement: element,
  };
}

export function isGalleryOpen(): boolean {
  return !!selectedGalleryImage;
}

export function selectGalleryImage(
  image: ImageData | null = null,
  replaceHistory = false,
): void {
  selectedGalleryImage = image;

  if (!image) {
    return;
  }

  setGalleryElements(image);

  if (replaceHistory) {
    window.history.replaceState(null, fullName, buildGalleryRoute(image.slug));
  }
}

function setGalleryElements(image: ImageData | null): void {
  if (image) {
    galleryImageElement.src = image.url;
    galleryImageElement.alt = image.name;
    galleryDescriptionElement.innerHTML = image.descriptionHtml;
  } else {
    galleryImageElement.src = '';
    galleryImageElement.alt = '';
    galleryDescriptionElement.innerHTML = '';
  }
}
