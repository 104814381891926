import { description } from '../constants';

import {
  isGalleryOpen,
  openGallery,
  closeGallery,
  selectedGalleryImage,
  getImageDataFromSlug,
  galleryCloseButtonElement,
  getImageDataFromLocation,
  selectGalleryImage,
} from './gallery';

export function attachHandlers(): void {
  attachFooterShareButtonClickHandler();
  attachHeroLinksHandlers();
  attachShowcaseItemsClickHandler();
  attachWindowPopStateHandler();
  attachGalleryCloseButtonClickHandler();
  attachFooterShareButtonClickHandler();
  attachGalleryShareButtonClickHandler();
}

function attachFooterShareButtonClickHandler(): void {
  document
    .getElementById('footer-share-button')
    ?.addEventListener('click', () => {
      share(window.location.href, description);
    });
}

async function share(url: string, text: string): Promise<void> {
  if (!navigator.share) {
    return;
  }

  await navigator.share({ url, text, title: document.title });
}

function attachHeroLinksHandlers(): void {
  const elements = document.querySelectorAll<HTMLAnchorElement>('.hero-link');

  for (const element of elements) {
    element.addEventListener('pointerenter', () => {
      for (const otherElement of elements) {
        if (otherElement !== element) {
          otherElement.classList.add('hero-link-inactive');
        }
      }
    });

    element.addEventListener('pointerleave', () => {
      for (const otherElement of elements) {
        if (otherElement !== element) {
          otherElement.classList.remove('hero-link-inactive');
        }
      }
    });
  }
}

function attachShowcaseItemsClickHandler(): void {
  for (const element of document.querySelectorAll<HTMLImageElement>(
    '.showcase-item',
  )) {
    element.addEventListener('click', () =>
      openGallery(getImageDataFromSlug(element.dataset.slug!)!, true),
    );
  }
}

function attachWindowPopStateHandler(): void {
  window.addEventListener('popstate', () => {
    if (/^\/$/.test(window.location.pathname) && isGalleryOpen()) {
      closeGallery(false);

      return;
    }

    const image = getImageDataFromLocation();

    if (image && !isGalleryOpen()) {
      openGallery(image, false);
    } else if (image && selectedGalleryImage?.slug !== image.slug) {
      selectGalleryImage(image, false);
    }
  });
}

function attachGalleryCloseButtonClickHandler(): void {
  galleryCloseButtonElement.addEventListener('click', () => {
    closeGallery(true);
  });
}

function attachGalleryShareButtonClickHandler(): void {
  document
    .getElementById('gallery-share-button')
    ?.addEventListener('click', () => {
      if (!selectedGalleryImage) {
        return;
      }

      share(window.location.href, selectedGalleryImage.name);
    });
}
