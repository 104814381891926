import { maybeRouteToGallery } from './gallery';
import { attachHandlers } from './handlers';
import { registerServiceWorker } from './serviceWorkerRegistration';

export function startApp(): void {
  attachHandlers();
  attachColorCycles();
  maybeRouteToGallery();
  maybeShowShareButtons();

  registerServiceWorker();
}

function maybeShowShareButtons(): void {
  if (!navigator.share) {
    return;
  }

  for (const element of document.querySelectorAll('.share-button'))
    element.classList.remove('display-none');
}

export function attachColorCycles(): void {
  for (const element of document.querySelectorAll<HTMLElement>(
    '.color-cycle',
  )) {
    let hue = Number.parseInt(element.dataset.colorHue!, 10);
    let saturation = Number.parseInt(element.dataset.colorSaturation!, 10);
    let lightness = Number.parseInt(element.dataset.colorLightness!, 10);

    window.setInterval(() => {
      hue = (hue + 3) % 360;

      if (saturation < 90) {
        saturation += 1;
      }

      if (lightness < 50) {
        lightness += 1;
      }

      element.style.color = `hsl(${hue}, ${saturation}%, ${lightness}%, 1)`;
    }, 4000);
  }
}
