
const isLocalhost =
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  /^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})){3}$/.test(
    window.location.hostname,
  );

export type Config = {
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
};

export function registerServiceWorker(config?: Config): void {
  if (__DEV__ || isLocalhost || !navigator.serviceWorker) {
    return;
  }

  if (document.readyState !== 'complete') {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('load', () => registerValidSW(config));
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    registerValidSW(config);
  }
}

async function registerValidSW(config?: Config): Promise<void> {
  try {
    const registration = await navigator.serviceWorker.register(
      `/service-worker.js`,
    );

    registration.onupdatefound = () => {
      const installingWorker = registration.installing;

      if (installingWorker === null) {
        return;
      }

      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            config?.onUpdate?.(registration);
          } else {
            config?.onSuccess?.(registration);
          }
        }
      };
    };
  } catch (error) {
    console.error(error)
  }
}
